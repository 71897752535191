import { useMutation, useQuery, useQueryClient } from "react-query";
import { useApiRequest } from "system/api/hooks";
import { generateUrlQuery } from "system/helpers/helperFunctions";
import { generatePath } from "react-router-dom";
import { useApiErrors } from "system/helpers/apiErrorHelper";
import { useSuccessToast } from "system/helpers/hooks";
import { ErrorType } from "system/helpers/types";
import {
  API_CREATE_MERCHANTS_CONFIGURATION_ITEM,
  API_CREATE_MERCHANTS_CONFIGURATION_MANUAL_RATE,
  API_DELETE_MERCHANTS_CONFIGURATION_MANUAL_RATE_ITEM,
  API_GET_MERCHANTS_CONFIGURATION,
  API_GET_MERCHANTS_CONFIGURATION_ITEM,
  API_GET_MERCHANTS_CONFIGURATION_MANUAL_RATES,
  API_GET_PAYMENT_GATEWAYS_BY_MERCHANT,
  API_UPDATE_MERCHANTS_CONFIGURATION_ITEM,
  API_UPDATE_MERCHANTS_CONFIGURATION_MANUAL_RATE
} from "system/api/apiUrls";
import { MerchantsConfigurationDataType, MerchantsConfigurationItemType, PaymentGatewaysDataType } from "./types";

const getMerchantsConfigurationKey = "getMerchantsConfigurationKey"; 
const getMerchantsConfigurationItemKey = "getMerchantsConfigurationItemKey"; 
const getMerchantsConfigurationManulRatesKey = "getMerchantsConfigurationManulRatesKey"; 
const getPaymentGatewaysByMerchantKey = "getPaymentGatewaysByMerchantKey"; 

export const useGetMerchantsConfigurations = (groupType?: any) => {
  const [apiRequest] = useApiRequest();  
  return useQuery(
    [getMerchantsConfigurationKey, groupType],
    (): Promise<MerchantsConfigurationDataType> =>
      apiRequest({
        url: generateUrlQuery(API_GET_MERCHANTS_CONFIGURATION, groupType),
        method: "get",
      }),
      {
        enabled: !!groupType,
      }
  );
};

export const useGetMerchantsConfigurationsItem = (id: number) => {
  const [apiRequest] = useApiRequest();  
  return useQuery(
    [getMerchantsConfigurationItemKey, id],
    (): Promise<MerchantsConfigurationItemType> =>
      apiRequest({
        url: generatePath(API_GET_MERCHANTS_CONFIGURATION_ITEM, { id }),
        method: "get",
      }),
  );
};

export const useUpdateMerchantsConfigurationsItem = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: API_UPDATE_MERCHANTS_CONFIGURATION_ITEM,
        method: "put",
        data: data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMerchantsConfigurationKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useGetManualRates = (merchantId: number, paymentGateway: string) => {
  const [apiRequest] = useApiRequest();  
  return useQuery(
    [getMerchantsConfigurationManulRatesKey, merchantId, paymentGateway],
    (): Promise<any> =>
      apiRequest({
        url: generatePath(API_GET_MERCHANTS_CONFIGURATION_MANUAL_RATES, { merchantId , paymentGateway}),
        method: "get",
      }),
  );
};

export const useUpdateMerchantsConfigurationsManualRate = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) => 
      apiRequest({
        url: API_UPDATE_MERCHANTS_CONFIGURATION_MANUAL_RATE,
        method: "put",
        data: data.data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMerchantsConfigurationManulRatesKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useDeleteMerchantsConfigurationsManualRateItem = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_DELETE_MERCHANTS_CONFIGURATION_MANUAL_RATE_ITEM, {
          id: data.id,
        }),
        method: "delete",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMerchantsConfigurationManulRatesKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};



export const useCreateManualRate = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: API_CREATE_MERCHANTS_CONFIGURATION_MANUAL_RATE,
        method: "post",
        data: data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMerchantsConfigurationManulRatesKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};


export const useCreateConfiguration= () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: API_CREATE_MERCHANTS_CONFIGURATION_ITEM,
        method: "post",
        data: data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMerchantsConfigurationKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useGetPaymentGatewaysByMerchant = (merchantId?: number) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getPaymentGatewaysByMerchantKey, merchantId],
    (): Promise<PaymentGatewaysDataType> =>
      apiRequest({
        url: generatePath(API_GET_PAYMENT_GATEWAYS_BY_MERCHANT, { merchantId: `${merchantId}`}),
        method: "get",
      }),
    { enabled: !!merchantId }
  );
};
