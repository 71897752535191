import {
  defaultPageSize,
  defaultSelectedPage,
} from "../../modules/table/constants";

const config = {
  operationTypes: [
    {
      label: "3D Purchase",
      value: "3D",
    },
    {
      label: "Cancel",
      value: "CANCEL",
    },
    {
      label: "Stored Purchase",
      value: "STORED",
    },
    {
      label: "Direct Purchase",
      value: "DIRECT",
    },
    {
      label: "3D Auth",
      value: "3DAUTH",
    },
    {
      label: "Refund",
      value: "REFUND",
    },
  ],
  transactionTypes: [
    {
      label: "Deposit",
      value: "DEPOSIT",
    },
    {
      label: "Withdrawal",
      value: "WITHDRAWAL",
    },
  ],
  transactionStatuses: [
    {
      label: "Approved",
      value: "APPROVED",
    },
    {
      label: "Canceled",
      value: "CANCELED",
    },
    {
      label: "Declined",
      value: "DECLINED",
    },
    {
      label: "Waiting",
      value: "WAITING",
    },
    {
      label: "Pending",
      value: "PENDING",
    },
    {
      label: "Error",
      value: "ERROR",
    },
  ],
  defaultInitialPagination: {
    page: defaultSelectedPage,
    perPage: defaultPageSize,
  },
  dateFormat: "yyyy-MM-dd",
  transactionTypesManualTransaction: [
    {
      label: "Movement adjustment",
      value: "movementAdjustment",
    },
    {
      label: "Balance adjustment",
      value: "balanceAdjustment",
    },
    {
      label: "Rolling reserve adjustment",
      value: "rollingReserveAdjustment",
    },
    {
      label: "On hold adjustment",
      value: "onHoldAdjustment",
    },
  ],
  adjustmentType: {
    movementAdjustment: [
      {
        label: "Increase balance",
        value: "increaseBalance",
      },
      {
        label: "Decrease balance",
        value: "decreaseBalance",
      },
    ],
    balanceAdjustment: [
      {
        label: "Add monthly fees",
        value: "addMonthlyFees",
      },
      {
        label: "Add setup fees",
        value: "addSetupFees",
      },
      {
        label: "Decrease merchant balance",
        value: "decreaseBalance",
      },
      {
        label: "Increase merchant balance",
        value: "increaseBalance",
      },
      {
        label: "Transfer to merchant",
        value: "transferToMerchant",
      },
      {
        label: "Received from merchant",
        value: "receivedFromMerchant",
      },
      {
        label: "Transfer to other merchant",
        value: "transferToAnotherMerchant",
      },
      // {
      //   label: "Add merchant transfer fees",
      //   value: "addMerchantTransferFees",
      // },
    ],
    
    rollingReserveAdjustment: [
      {
        label: "Add Rolling Reserve",
        value: "addRollingReserve",
      },
      {
        label: "Return Rolling Reserve",
        value: "returnRollingReserve",
      },
    ],
    onHoldAdjustment: [
      {
        label: "Add On Hold",
        value: "addOnHold",
      },
      {
        label: "Return On Hold",
        value: "returnOnHold",
      },
    ],
    
  },
  monthNames: ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
  ],
  MIMItypes: {
    css: 'text/css',
    csv: 'text/csv',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    gif: 'image/gif',
    gz: 'application/gzip',
    ico: 'image/vnd.microsoft.icon',
    jpeg: 'image/jpeg',
    jpg: 'image/jpeg',
    js: 'text/javascript',
    json: 'application/json',
    mp3: 'audio/mpeg',
    mp4: 'video/mp4',
    mpeg: 'video/mpeg',
    pdf: 'application/pdf',
    png: 'image/png',
    ppt: 'application/vnd.ms-powerpoint',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    rar: 'application/vnd.rar',
    rtf: 'application/rtf',
    svg: 'image/svg+xml',
    tar: 'application/x-tar',
    tiff: 'image/tiff',
    txt: 'text/plain',
    wav: 'audio/wav',
    webp: 'image/webp',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    xml: 'application/xml',
    zip: 'application/zip'
  },
  ipnSentOptions: [
    { label: "Sent", value: true },
    { label: "Not Sent", value: false },
  ]
};

export default config;
