import React, { useEffect, useMemo, useState } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "../formBuilder/types";
import { useDefaultFieldsConfig } from "modules/formBuilder/hooks";
import config from "./constants";
import { Checkmark16, Save16 } from "@carbon/icons-react";
import { TableColumnType } from "modules/table/types";
import { format } from "date-fns";
import ChangeValueTooltip from "components/molecules/ChangeValueTooltip";
import { useGetWarningText, useModuleData, useModuleDataCreate } from "./hooks";
import DotsMenu from "components/atoms/DotsMenu";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import Toggle from "components/atoms/Toggle";


const useEditManualRatesConfig = (type: string): FormConfigType => {
  const { _t } = useTrans()
  const fields = useDefaultFieldsConfig();
  const { currenciesOptions } = useModuleData();
  const formGeneral = [
    fields.general.divider(),
    fields.general.saveBtn({
      componentProps: {
        renderIcon: Checkmark16,
        label: '',
      },
      columnParams: {
        className: 'popover-tooltip__saveBtn'
      }
    }),
  ]
  const formConfig = useMemo((): FormConfigType => {
    switch (type) {
      case 'currency':
        return [
          fields.formFields.selectCurrenciesWithSearch({
            name: "currencyFrom",
            componentProps: {
              options: currenciesOptions,
              labelText: _t("currency") + ' from',
              placeholderText: _t('select_currency')
            },
            columnParams: {
              className: 'align-input max-width-select-with-search one-before',
              lg: 6,
              md: 2,
            }
          }),
          fields.formFields.selectCurrenciesWithSearch({
            name: "currencyTo",
            componentProps: {
              options: currenciesOptions,
              labelText: _t("currency") + ' to',
              placeholderText: _t('select_currency')
            },
            columnParams: {
              className: 'align-input max-width-select-with-search equalOne-before',
              lg: 6,
              md: 8,
            }
          }),
          ...formGeneral
        ]
      case 'rate':
        return [
          fields.formFields.percentage({
            name: 'rate',
            componentProps: {
              labelText: _t("rate_"),
            },
            columnParams: {
              className: 'small-input__container-left small-input__container-right',
              lg: 16,
              md: 8,
            }
          }),
          ...formGeneral
        ];
      case 'correlation':
        return [
          fields.formFields.percentage({
            name: 'rateCorrelation',
            componentProps: {
              labelText: _t("correlation") + ', %',
            },
            columnParams: {
              className: 'small-input__container-left small-input__container-right',
              lg: 16,
              md: 8,
            }
          }),
          ...formGeneral
        ]
      case 'period':
        return [
          fields.formFields.validFrom({
            name: 'dateFrom',
            componentProps: {
              labelText: _t("start_period"),
            },
            columnParams: {
              lg: 8,
              md: 8,
              className: 'small-input__container-right'
            }
          }),
          fields.formFields.validFrom({
            name: 'dateTo',
            componentProps: {
              labelText: _t("end_period"),
            },
            columnParams: {
              lg: 8,
              md: 8,
              className: 'small-input__container-left'
            }
          }),
          ...formGeneral
        ]
      default:
        return []
    }

  }, [type, currenciesOptions]);
  return formConfig;
};

export const useTableExchangeRates = (): TableColumnType[] => {
  const { _t } = useTrans();
  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("currency"),
        accessor: "currency",
        id: "currency",
        disableSortBy: true,
        Cell: ({ row, onUpdateCreatedManualRate, onUpdateManualRate }: any) => {
          const formConfig: any = useEditManualRatesConfig('currency')
          const isUpdateRate = row?.original?.currencyFrom && row?.original?.currencyTo
          return (
              <ChangeValueTooltip
                formConfig={formConfig}
                dataValue={isUpdateRate
                  ? `1 ${row?.original?.currencyFrom} = 1 ${row?.original?.currencyTo}`
                  : <div className="exchange-rates--cell-text">{_t('select_currency')}</div>
                }
                initialValues={row.original}
                bigTooltipClass="smallTooltipClass popover-tooltip-modal"
                onUpdate={row?.original?.id !== undefined && isUpdateRate ? onUpdateManualRate : onUpdateCreatedManualRate}
              />
          )
        }
      },
      {
        Header: _t("rate_"),
        accessor: "rate",
        id: "rate",
        maxWidth: 100,
        disableSortBy: true,
        Cell: ({row, onUpdateCreatedManualRate, onUpdateManualRate } : any) => {
          const formConfig: any = useEditManualRatesConfig('rate')
          const isUpdateRate = !!row?.original?.rate
          return (
              <ChangeValueTooltip
                formConfig={formConfig}
                dataValue={isUpdateRate
                  ? row?.original?.rate
                  : <div className="exchange-rates--cell-text">0</div>
                }
                initialValues={row.original}
                bigTooltipClass="smallTooltipClass popover-tooltip-modal"
                onUpdate={row?.original?.id !== undefined && isUpdateRate ? onUpdateManualRate : onUpdateCreatedManualRate}
              />
          )
        }
      },
      {
        Header: _t("correlation") + ', %',
        accessor: "rateCorrelation",
        id: "rateCorrelation",
        maxWidth: 120,
        disableSortBy: true,
        Cell: ({ row, onUpdateCreatedManualRate, onUpdateManualRate }: any) => {
          const formConfig: any = useEditManualRatesConfig('correlation')
          const isUpdateRate = !!row?.original?.rateCorrelation
          return (
                <ChangeValueTooltip
                  formConfig={formConfig}
                  dataValue={isUpdateRate 
                    ? row?.original?.rateCorrelation + ' %'
                    : <div className="exchange-rates--cell-text">0</div>
                  }
                  initialValues={row.original}
                  bigTooltipClass="smallTooltipClass popover-tooltip-modal"
                  onUpdate={row?.original?.id !== undefined && isUpdateRate ? onUpdateManualRate : onUpdateCreatedManualRate}
                />
          )
        }
      },
      {
        Header: _t("period"),
        accessor: "period",
        id: "period",
        disableSortBy: true,
        Cell: ({row, onUpdateCreatedManualRate, onUpdateManualRate } : any) => {
          const formConfig: any = useEditManualRatesConfig('period')
          const isUpdateRate = row?.original?.dateFrom && row?.original?.dateTo
          return (
              <ChangeValueTooltip
                formConfig={formConfig}
                dataValue={isUpdateRate
                  ? `${format(new Date(row?.original?.dateFrom), "dd/MM/yyyy")} - ${format(new Date(row?.original?.dateTo), "dd/MM/yyyy")}`
                  : <div className="exchange-rates--cell-text">{_t('select_period')}</div> 
                }
                initialValues={row.original}
                bigTooltipClass="smallTooltipClass popover-tooltip-modal"
                onUpdate={row?.original?.id !== undefined && isUpdateRate ? onUpdateManualRate : onUpdateCreatedManualRate}
              />
          )
        }
      },
      {
        maxWidth: 40,
        Header: "",
        id: "actionMenu",
        mobileVisible: true,
        Cell: (props: any) => {
          return (
            <DotsMenu
              clickParams={{
                id: props?.row?.original?.id,
                allData: props?.row?.original,
              }}
              items={props?.actionMenuItems}
            />
          );
        },
      },
    ],
    []
  );

  return columns;
};

export const useSearchFormConfig = (setColumnOrderPSPToMerchant: any, groupBy: string): FormConfigType => {
  const { _t } = useTrans();
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.filters.filterValue({
        name: 'filterValue',
        componentProps: {
          showSearch: true,
          type: "submit",
          placeHolderText: groupBy === _t('merchant') 
          ? _t("search_by_merchant")
          : _t("search_by_psp")
        },
        columnParams: {
          md: 6,
          lg: 8,
          className: ''
        },
      }),
      fields.filters.groupBy({
        componentProps: {
          onValueChange: setColumnOrderPSPToMerchant
        },
        columnParams: {
          className: 'radio-button__container radio-with-margin'
        }
      }),
      fields.general.submitBtn(),
    ];
  }, [groupBy, setColumnOrderPSPToMerchant]);
  return formConfig;
};

export const useEditConfig = (
  initialValues: any,
  configurationType: string,
  setConfigurationType: React.Dispatch<React.SetStateAction<string>>,
  setInitialValues: React.Dispatch<React.SetStateAction<{}>>
  ): FormConfigType => {
  const { _t } = useTrans()
  const { currenciesOptions, parentGatewaysWithConfiguration } = useModuleDataCreate({
    id: initialValues?.merchantId,
    name: initialValues?.merchantName
  })
  const { openParentGatewayChangeWarning } = useGetWarningText()
  const fields = useDefaultFieldsConfig();
  const [strategy, setStrategy] = useState({
    fieldName: '',
    strategy: '',
  })
  const [isConvertedCurrency, setIsConvertedCurrency] = useState(!!initialValues?.convertCurrency)
  
  useEffect(() => {
    setIsConvertedCurrency(!!initialValues?.convertCurrency)
  }, [JSON.stringify(initialValues)])

  useEffect(() => {
    setStrategy({
      fieldName: configurationType + "Auto",
      strategy: initialValues?.[configurationType + "Auto"]
    })
  }, [configurationType, JSON.stringify(initialValues)])

  const formConfigConfigurationType = useMemo(() => {
    return [
      fields.formFields.configurationType({
        componentProps: {
          items: config.configurationType,
          onFieldChange: (value: any, values: any, form: any) => {
            const fieldsToResetValues: any = ["rollingReserveAuto", "rollingReserveReturnAuto" ,"rollingReserveRate", 
            "rollingReservePeriod", "onHoldAuto", "onHoldReturnAuto", "onHoldPeriod", "otherFeesType", 
            "otherFeesApplyTo", "calculationTime", "onHoldStrategyValidFrom", "rollingReserveStrategyValidFrom", "ratesStrategyValidFrom", "otherFeesValidFrom",
            "otherFeesComment", 'otherFeesType', 'otherFeesApplyTo', 'rateCorrelation','ratesApplication', 'convertCurrency' ]
            
            fieldsToResetValues.forEach((field: any) => {
              form.setFieldValue(field, initialValues[field])
              form.setFieldTouched(field, false);
            })
            
            setConfigurationType(value);

            if (values.activeConvertCurrency !== !!initialValues?.convertCurrency) {
              form.setFieldValue('activeConvertCurrency', !!initialValues?.convertCurrency)
              form.setFieldTouched('activeConvertCurrency', false);
              setIsConvertedCurrency(!!initialValues?.convertCurrency)
            }
            
          },
        },
      }),
      fields.general.divider(),
    ]
  }, [initialValues])

  const formGeneral = [
    fields.general.divider(),
    fields.general.saveBtn({
      componentProps: {
        renderIcon: Save16,
      },
      
    }),
  ]
  
  const formConfig = useMemo((): FormConfigType => {
    switch (configurationType) {
      case 'rollingReserve':
        return [
          ...formConfigConfigurationType,
          fields.formFields.strategy({
            name: 'rollingReserveAuto',
            componentProps: {
              items: config.strategy,
              labelText: _t("rolling_reserve_strategy"),
              placeholderText: _t('select_rolling_reserve_strategy'),
              onFieldChange: (value: any, values: any, form: any) => {
                  setStrategy(prev => ({...prev, fieldName: 'rollingReserveAuto', strategy: value }))
                  form.setFieldValue('rollingReserveRate', initialValues.rollingReserveRate)
                  form.setFieldTouched('rollingReserveRate', false)
                  form.setFieldValue('rollingReservePeriod', initialValues.rollingReservePeriod)
                  form.setFieldTouched('rollingReservePeriod', false)

                  if (!value) {
                    form.setFieldValue('rollingReserveReturnAuto', false)
                    form.setFieldTouched('rollingReserveReturnAuto', false)
                  }
              }
            }
          }),
          fields.formFields.strategy({
            name: 'rollingReserveReturnAuto',
            componentProps: {
              items: config.strategy,
              labelText: _t("return_rolling_reserve_strategy"),
              placeholderText: _t('select_return_rolling_reserve_strategy'),
              disabled: strategy.fieldName === 'rollingReserveAuto' && !strategy.strategy
            },
            columnParams: {
              className: 'small-input__container-left'
            }
          }),
          fields.formFields.percentage({
            name: 'rollingReserveRate',
            componentProps: {
              labelText: _t("percentage"),
              disabled: strategy.fieldName === 'rollingReserveAuto' && !strategy.strategy
            },
            columnParams: {
              className: 'small-input__container-right'
            }
          }),
          fields.formFields.numberOfDays({
            name: 'rollingReservePeriod',
            componentProps: {
              items: config.strategy,
              labelText: _t("period_in_days"),
              disabled: strategy.fieldName === 'rollingReserveAuto' && !strategy.strategy
            },
            columnParams: {
              lg: 8,
              md: 8,
              className: 'small-input__container-left'
            }
          }),
          fields.formFields.monthPicker({
            name: 'rollingReserveStrategyValidFrom',
            componentProps: {
              labelText: _t("valid_from"),
            },
            columnParams: {
              lg: 8,
              md: 8,
              className: 'small-input__container-right'
            }
          }),
          ...formGeneral,
        ]
      case 'onHold':
        return [
          ...formConfigConfigurationType,
          fields.formFields.strategy({
            name: 'onHoldAuto',
            componentProps: {
              items: config.strategy,
              labelText: _t("onhold_strategy"),
              placeholderText: _t('select_onhold_strategy'),
              onFieldChange: (value: any, values: any, form: any) => {
                setStrategy(prev => ({...prev, fieldName: 'onHoldAuto', strategy: value }))
                form.setFieldValue('onHoldPeriod', initialValues.onHoldPeriod)
                form.setFieldTouched('onHoldPeriod', false)

                if (!value) {
                  form.setFieldValue('onHoldReturnAuto', false)
                  form.setFieldTouched('onHoldReturnAuto', false)
                }
              }
            },
            columnParams: {
              className: 'small-input__container-right'
            }
          }),
          fields.formFields.strategy({
            name: 'onHoldReturnAuto',
            componentProps: {
              items: config.strategy,
              labelText: _t("return_onhold_strategy"),
              placeholderText: _t('select_return_onhold_strategy'),
              disabled: strategy.fieldName === 'onHoldAuto' && !strategy.strategy
            },
            columnParams: {
              className: 'small-input__container-left'
            }
          }),
          fields.general.infoBlock({
            componentProps: {
              text: _t('onhold_formula'),
              additionalText: _t('formula')
            }
          }),
          fields.formFields.numberOfDays({
            name: 'onHoldPeriod',
            componentProps: {
              items: config.strategy,
              labelText: _t("period_in_days"),
              disabled: strategy.fieldName === 'onHoldAuto' && !strategy.strategy
            },
            columnParams: {
              lg: 8,
              md: 8,
              className: 'small-input__container-right'
            }
          }),
          fields.formFields.monthPicker({
            name: 'onHoldStrategyValidFrom',
            componentProps: {
              labelText: _t("valid_from"),
            },
            columnParams: {
              lg: 8,
              md: 8,
              className: 'small-input__container-left'
            }
          }),
          ...formGeneral
        ];
      case 'otherFees':
        return [
          ...formConfigConfigurationType,
          fields.formFields.name({
            name: "otherFeesComment",
            componentProps: {
              labelText: _t("comment_name"),
              placeholder: ''
            }
          }),
          fields.formFields.strategy({
            name: 'otherFeesType',
            componentProps: {
              items: config.otherFees.type,
              labelText: _t("type"),
              placeholderText: _t('select_type')
            },
            columnParams: {
              className: 'small-input__container-right'
            }
          }),
          fields.formFields.strategy({
            name: 'otherFeesApplyTo',
            componentProps: {
              items: config.otherFees.applyTo,
              labelText: _t("apply_to"),
              placeholderText: _t('select_type')
            },
            columnParams: {
              className: 'small-input__container-left'
            }
          }),
          fields.formFields.strategy({
            name: 'calculationTime',
            componentProps: {
              items: config.otherFees.calculationTime,
              labelText: _t("calculation_time"),
              placeholderText: _t('select_period_start')
            },
            columnParams: {
              className: 'small-input__container-right'
            }
          }),
          fields.formFields.validFrom({
            name: 'otherFeesValidFrom',
            componentProps: {
              labelText: _t("valid_from"),
            },
            columnParams: {
              lg: 8,
              md: 8,
              className: 'small-input__container-left fdsfsdf'
            }
          }),
          ...formGeneral
        ]
      case 'exchangeRate':
        return [
          ...formConfigConfigurationType,
          fields.formFields.strategy({
            name: 'ratesApplication',
            componentProps: {
              items: config.exchangeRate.rateApplication,
              labelText: _t("rate_application"),
              placeholderText: _t('select_rate_application')
            },
            columnParams: {
              className: 'small-input__container-right',
              lg: 6,
              md: 8,
            }
          }),
          fields.formFields.percentage({
            name: 'rateCorrelation',
            componentProps: {
              labelText: _t("correlation") + ', %',
            },
            columnParams: {
              className: 'small-input__container-left small-input__container-right',
              lg: 5,
              md: 8,
            }
          }),
          fields.formFields.validFrom({
            name: 'ratesStrategyValidFrom',
            componentProps: {
              labelText: _t("valid_from"),
            },
            columnParams: {
              lg: 5,
              md: 8,
            }
          }),
          ...formGeneral
        ]
      case 'convertCurrency':
        return [
          ...formConfigConfigurationType,
          fields.general.infoBlock({
            componentProps: {
              text: _t('be_careful'),
              withIcon: true,
              additionalText: _t('conversion_can_be_set_once')
            }
          }),
          fields.formFields.activeToggle({
            name: 'activeConvertCurrency',
            componentProps: {
              labelA: _t('calculation_currency_off'),
              labelB: _t('calculation_currency_on'),
              labelText: '',
              disabled: !!initialValues?.convertCurrency,
              onFieldChange: (value: any, values: any, form: any) => {
                if (!value) {
                  form.setFieldValue('convertCurrency', null)
                  form.setFieldTouched('convertCurrency', false);
                }
                setIsConvertedCurrency(value)
              },
            },
            columnParams: {
              lg: 8,
              md: 4,
              className: 'position-toggle-row--center'
            },
          }),
          fields.formFields.selectCurrenciesWithSearch({
            name: 'convertCurrency',
            componentProps: {
              items: currenciesOptions,
              labelText: _t('calculation_currency'),
              disabled: !isConvertedCurrency || !!initialValues?.convertCurrency
            },
            columnParams: {
              lg: 8,
              md: 4,
              className: 'small-input__container-left'
            },
          }),
          ...formGeneral
        ]
      case "pspLinkage": 
        return [
          ...formConfigConfigurationType,
          fields.formFields.activeToggle({
            name: 'enableParentGatewayLinkage',
            componentProps: {
              labelText: _t("enable_link_to_parent_gateway"),
              withControlledValue: true,
              onFieldChange: (value: any, values: any, form: any) => {
                if (!value) {
                  form.setFieldValue('parentPaymentGatewayCode', null)
                  form.setFieldTouched('parentPaymentGatewayCode', false);
                }
              },
            },
            columnParams: {
              lg: 8,
              md: 4,
              className: 'position-toggle-row--center'
            },
          }),
          {
            component: SelectWithSearch,
            name: 'parentPaymentGatewayCode',
            componentProps: (form: any) => {
              return {
                items: parentGatewaysWithConfiguration.filter((option => option.value.paymentGatewayCode !== form.values?.paymentGatewayCode)),
                labelText: _t('parent_gateway'),
                placeholderText: _t("select_parent_gateway"),
                disabled: !form.values.enableParentGatewayLinkage,
                onFieldChange: (value: any, values: any, formChangers: any) => {
                  openParentGatewayChangeWarning()
                  const {
                    configurationType,
                    enableParentGatewayLinkage,
                    paymentGatewayCode,
                    merchantId,
                    id
                  } = values

                  const newInitialValues = {
                    ...value,
                    activeConvertCurrency: !!value?.convertCurrency,
                    parentPaymentGatewayCode: value,
                    configurationType,
                    enableParentGatewayLinkage,
                    paymentGatewayCode,
                    merchantId,
                    id,
                  }
                  setInitialValues(newInitialValues)
                }
              }
            },
            columnParams: {
              lg: 8,
              md: 4,
              className: 'small-input__container-left'
            }
          },
          ...formGeneral
        ]
      default:
        return [...formConfigConfigurationType]
    }

  }, [
    configurationType,
    initialValues,
    strategy,
    isConvertedCurrency,
    currenciesOptions,
    parentGatewaysWithConfiguration
  ]);
  return formConfig;
};


export const useCreateConfig = (setInitialValues: React.Dispatch<React.SetStateAction<{}>>, initialValues: any): FormConfigType => {
  const { _t } = useTrans()
  const fields = useDefaultFieldsConfig();
  const [ merchant, setMerchant ] = useState(undefined)
  const [ isAllGatewaysDisplay, setIsAllGatewaysDisplay ] = useState(false)
  const {
    paymentGatewaysOptionsAll, 
    paymentGatewaysOptionsByMerchant,
    merchantsOptions,
    currenciesOptions,
    parentGatewaysWithConfiguration
  } = useModuleDataCreate(merchant);

  const filterGateways = (field?: any) => {
    console.log('--------', parentGatewaysWithConfiguration, field);
    
    return !!field
        ? parentGatewaysWithConfiguration.filter(parentGateway => parentGateway.value.paymentGatewayCode !== field?.label)
        : parentGatewaysWithConfiguration
  }
console.log(paymentGatewaysOptionsByMerchant);

  const isDisabledField = !!initialValues?.parentPaymentGatewayCode
  
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.merchantIdSingle({
        componentProps: {
          items: merchantsOptions,
          light: false,
          disabled: isDisabledField,
          onFieldChange: (value: any) => {
            setMerchant(value);
          },
        },
      }),
      {
        component: Toggle,
        name: "getAllGateways",
        componentProps: (form: any) => {
          return {
            labelText: _t("show_all_gateways"),
            placeHolderText: _t("show_all_gateways"),
            withControlledValue: true,
            id: "getAllGateways",
            value: isAllGatewaysDisplay,
            disabled: isDisabledField,
            onFieldChange: (value: any, values: any, form: any) => {
              form.setFieldValue('paymentGatewayCode', "")
              form.setFieldTouched('paymentGatewayCode', false);
              setIsAllGatewaysDisplay(value)
            },
          }
        },
        columnParams: {
          lg: 16,
          md: 16,
        },
      },
      fields.formFields.paymentGatewayCode({
        componentProps: {
          labelText: _t('payment_providers'),
          options: isAllGatewaysDisplay ? paymentGatewaysOptionsAll : paymentGatewaysOptionsByMerchant,
          whiteBackground: true,
          wrapClassPrefix: 'dropdown-full-width',
          withTitleText: true,
          previewSelectedItems: true,
          disabled: isDisabledField,
          onFieldChange: (value: any, values: any, form: any) => {
            console.log(value);
            
              form.setFieldValue('enableParentGatewayLinkage', false)
              form.setFieldTouched('enableParentGatewayLinkage', false);
          },
        },
      }),
      fields.general.divider(),
      fields.general.infoBlock({
        componentProps: {
          text: _t("psp_linkage"),
          withIcon: false
        }
      }),
      {
        component: Toggle,
        name: 'enableParentGatewayLinkage',
        componentProps: (form: any) => {
          return {
            labelText: _t("enable_link_to_parent_gateway"),
            id: "enableParentGatewayLinkage",
            withControlledValue: true,
            disabled: !form.values?.paymentGatewayCode || filterGateways(form.values?.paymentGatewayCode).length === 0,
            onFieldChange: (value: any, values: any, form: any) => {
              if (!value) {
                form.setFieldValue('parentPaymentGatewayCode', null)
                form.setFieldTouched('parentPaymentGatewayCode', false);
                setInitialValues({
                  merchantId: values.merchantId,
                  paymentGatewayCode: values.paymentGatewayCode
                })
              }
            },
          }
        },
        columnParams: {
          lg: 8,
          md: 4,
          className: 'position-toggle-row--center'
        },
      },
      {
        component: SelectWithSearch,
        name: 'parentPaymentGatewayCode',
        componentProps: (form: any) => {
          return {
            items: filterGateways(form.values?.paymentGatewayCode),
            labelText: _t('parent_gateway'),
            placeholderText: _t("select_parent_gateway"),
            disabled: !form.values.enableParentGatewayLinkage,
            onFieldChange: (value: any, values: any, formChangers: any) => {
              const newInitialValues = {
                ...value,
                parentPaymentGatewayCode: value,
                merchantId: values.merchantId,
                paymentGatewayCode: values.paymentGatewayCode,
                enableParentGatewayLinkage: values.enableParentGatewayLinkage,
                getAllGateways: values?.getAllGateways,
                activeConvertCurrency: !!value?.convertCurrency
              }
              delete newInitialValues?.id
              setInitialValues(newInitialValues)
            }
          }
        },
        columnParams: {
          lg: 8,
          md: 4,
          className: 'small-input__container-left'
        },
      },
      fields.general.divider(),
      fields.general.infoBlock({
        componentProps: {
          text: _t("rolling_reserve_config"),
          withIcon: false
        }
      }),
      fields.formFields.strategy({
        name: 'rollingReserveAuto',
        componentProps: {
          items: config.strategy,
          labelText: _t("rolling_reserve_strategy"),
          placeholderText: _t('select_rolling_reserve_strategy'),
          disabled: isDisabledField
        },
      }),
      fields.formFields.strategy({
        name: 'rollingReserveReturnAuto',
        componentProps: {
          items: config.strategy,
          labelText: _t("return_rolling_reserve_strategy"),
          placeholderText: _t('select_return_rolling_reserve_strategy'),
          disabled: isDisabledField
        },
        columnParams: {
          className: 'small-input__container-left'
        }
      }),
      fields.formFields.percentage({
        name: 'rollingReserveRate',
        componentProps: {
          labelText: _t("percentage"),
          disabled: isDisabledField
        },
        columnParams: {
          className: 'small-input__container-right'
        }
      }),
      fields.formFields.numberOfDays({
        name: 'rollingReservePeriod',
        componentProps: {
          items: config.strategy,
          labelText: _t("period_in_days"),
          disabled: isDisabledField
        },
        columnParams: {
          lg: 8,
          md: 8,
          className: 'small-input__container-left'
        }
      }),
      fields.formFields.monthPicker({
        name: 'rollingReserveStrategyValidFrom',
        componentProps: {
          labelText: _t("valid_from"),
          disabled: isDisabledField
        },
        columnParams: {
          lg: 8,
          md: 8,
          className: 'small-input__container-right'
        }
      }),
      fields.general.divider(),
      fields.general.infoBlock({
        componentProps: {
          text: _t("on_hold_config"),
          withIcon: false,
        }
      }),
      fields.formFields.strategy({
        name: 'onHoldAuto',
        componentProps: {
          items: config.strategy,
          labelText: _t("onhold_strategy"),
          placeholderText: _t('select_onhold_strategy'),
          disabled: isDisabledField
        },
        columnParams: {
          className: 'small-input__container-right'
        }
      }),
      fields.formFields.strategy({
        name: 'onHoldReturnAuto',
        componentProps: {
          items: config.strategy,
          labelText: _t("return_onhold_strategy"),
          placeholderText: _t('select_return_onhold_strategy'),
          disabled: isDisabledField
        },
        columnParams: {
          className: 'small-input__container-left'
        }
      }),
      fields.general.infoBlock({
        componentProps: {
          text: _t('onhold_formula'),
          additionalText: _t('formula'),
        }
      }),
      fields.formFields.numberOfDays({
        name: 'onHoldPeriod',
        componentProps: {
          items: config.strategy,
          labelText: _t("period_in_days"),
          disabled: isDisabledField
        },
        columnParams: {
          lg: 8,
          md: 8,
          className: 'small-input__container-right'
        }
      }),
      fields.formFields.monthPicker({
        name: 'onHoldStrategyValidFrom',
        componentProps: {
          labelText: _t("valid_from"),
          disabled: isDisabledField
        },
        columnParams: {
          lg: 8,
          md: 8,
          className: 'small-input__container-left'
        }
      }),
      fields.general.divider(),

      // fields.general.infoBlock({
      //   componentProps: {
      //     text: _t("other_fees_config"),
      //     withIcon: false
      //   }
      // }),
      // fields.formFields.name({
      //   name: "otherFeesComment",
      //   componentProps: {
      //     labelText: _t("comment_name"),
      //     placeholder: ''
      //   }
      // }),
      // fields.formFields.strategy({
      //   name: 'otherFeesType',
      //   componentProps: {
      //     items: config.otherFees.type,
      //     labelText: _t("type"),
      //     placeholderText: _t('select_type')
      //   },
      //   columnParams: {
      //     className: 'small-input__container-right'
      //   }
      // }),
      // fields.formFields.strategy({
      //   name: 'otherFeesApplyTo',
      //   componentProps: {
      //     items: config.otherFees.applyTo,
      //     labelText: _t("apply_to"),
      //     placeholderText: _t('select_type')
      //   },
      //   columnParams: {
      //     className: 'small-input__container-left'
      //   }
      // }),
      // fields.formFields.strategy({
      //   name: 'calculationTime',
      //   componentProps: {
      //     items: config.otherFees.calculationTime,
      //     labelText: _t("calculation_time"),
      //     placeholderText: _t('select_period_start')
      //   },
      //   columnParams: {
      //     className: 'small-input__container-right'
      //   }
      // }),
      // fields.formFields.validFrom({
      //   name: 'otherFeesValidFrom',
      //   componentProps: {
      //     labelText: _t("valid_from"),
      //   },
      //   columnParams: {
      //     lg: 8,
      //     md: 8,
      //     className: 'small-input__container-left fdsfsdf'
      //   }
      // }),
      // fields.general.divider(),

      fields.general.infoBlock({
        componentProps: {
          text: _t("exchange_rate_config"),
          withIcon: false
        }
      }),
      fields.formFields.strategy({
        name: 'ratesApplication',
        componentProps: {
          items: config.exchangeRate.rateApplication,
          labelText: _t("rate_application"),
          placeholderText: _t('select_rate_application'),
          disabled: isDisabledField
        },
        columnParams: {
          className: 'small-input__container-right',
          lg: 6,
          md: 8,
        }
      }),
      fields.formFields.percentage({
        name: 'rateCorrelation',
        componentProps: {
          labelText: _t("correlation") + ', %',
          disabled: isDisabledField
        },
        columnParams: {
          className: 'small-input__container-left small-input__container-right',
          lg: 5,
          md: 8,
        }
      }),
      fields.formFields.validFrom({
        name: 'ratesStrategyValidFrom',
        componentProps: {
          labelText: _t("valid_from"),
          disabled: isDisabledField
        },
        columnParams: {
          lg: 5,
          md: 8,
        }
      }),
      fields.general.divider(),
      fields.general.infoBlock({
        componentProps: {
          text: _t('calculation_currency'),
          withIcon: false,
        }
      }),
      fields.general.infoBlock({
        componentProps: {
          text: _t('be_careful'),
          withIcon: true,
          additionalText: _t('conversion_can_be_set_once')
        }
      }),
      fields.formFields.activeToggle({
        name: 'activeConvertCurrency',
        componentProps: {
          labelA: _t('calculation_currency_off'),
          labelB: _t('calculation_currency_on'),
          labelText: '',
          disabled: isDisabledField,
          id: "activeConvertCurrency",
          withControlledValue: true,
          onFieldChange: (value: any, values: any, form: any) => {
            if (!value) {
              form.setFieldValue('convertCurrency', null)
              form.setFieldTouched('convertCurrency', false);
            }
          },
        },
        columnParams: {
          lg: 8,
          md: 4,
          className: 'position-toggle-row--center'
        },
      }),
      {
        component: SelectWithSearch,
        name: 'convertCurrency',
        componentProps: (form: any) => {
          return {
            items: currenciesOptions,
            labelText: _t('calculation_currency'),
            disabled: !form.values.activeConvertCurrency || isDisabledField
          }
        },
        columnParams: {
          lg: 8,
          md: 4,
          className: 'small-input__container-left'
        },
      },
      fields.general.divider(),
      fields.general.saveBtn({
        componentProps: {
          renderIcon: Save16,
        },
      }),
    ]
  }, [
    isAllGatewaysDisplay,
    paymentGatewaysOptionsAll,
    paymentGatewaysOptionsByMerchant,
    merchantsOptions,
    parentGatewaysWithConfiguration,
    initialValues
  ]);
  return formConfig;
};
