import React, { useState } from "react";
import {  Column, Grid, Row } from "carbon-components-react";
import { useTrans } from "system/translations/hooks";
import Card from "components/atoms/Card";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useCreateConfig } from "../constantHooks";
import { useCreateConfiguration } from "../apiHooks";
import { createEditValidation } from "../validation";
import { useGetWarningText } from "../hooks";

const Create: React.FC = () => {
  const { _t } = useTrans();
  const { mutate: onCreate } = useCreateConfiguration()
  const { openWarning } = useGetWarningText()
  const [ initialValues, setInitialValues] = useState({})
  const formConfig = useCreateConfig(setInitialValues, initialValues);

  const onSubmit = async (data: any) => {
    const normalize = { ...data }
    normalize.merchantName = normalize.merchantId.name
    normalize.merchantId = normalize.merchantId.id
    normalize.paymentGatewayName = normalize.paymentGatewayCode.value
    normalize.paymentGatewayCode = normalize.paymentGatewayCode.label 
    normalize.parentPaymentGatewayName = normalize.parentPaymentGatewayCode?.paymentGatewayName 
    normalize.parentPaymentGatewayCode = normalize.parentPaymentGatewayCode?.paymentGatewayCode 
    
    !!normalize.convertCurrency 
      ? openWarning(() => onCreate(normalize) ) 
      : onCreate(normalize)
  };

  return (
    <Grid style={{ paddingLeft: "1rem" }} condensed>
      <Row>
        <Column
          lg={{ span: 8, offset: 4 }}
          md={{ span: 15, offset: 1 }}
          sm={{ span: 16, offset: 0 }}
        >
          <Card title={_t("create_configuration")}>
            <FormBuilder
              formItemsConfig={formConfig}
              showSubmit={false}
              formProps={{
                enableReinitialize: true,
                submitBtnLabel: _t("save_changes"),
                onSubmit: onSubmit,
                initialValues: initialValues,
                validationSchema: createEditValidation(_t, {
                  isEdit: false,
                }),
              }}
            />
          </Card>
        </Column>
      </Row>
    </Grid>
  );
};

export default Create;
